<template>
  <div class="OverviewRequisition">
    <Header />

    <!-- Content -->
    <div class="PageContentWrap">
      <div class="InnerWrap">

        <div class="NewRequisition">
          <SectionHeadline :text="mixWB('NEW_REQUISITION')" />

          <p>{{
            mixWB('REQUISITION_INTRO_TEXT')
          }}</p>

          <p
            v-if="!isRequisitionFlowAvailable"
            class="EmptyText">{{
              mixWB('NO_SAMPLES_TAKEN_YET')
            }}</p>

          <Button
            :path="{
              name: 'RequisitionCreation',
              params: { screeningID: currentScreening.id },
            }"
            :isDisabled="!isRequisitionFlowAvailable"
            :text="mixWB('CREATE_REQUISITION')"
            size="small"
            :fullWidth="false" />

        </div>

        <div class="ExistingRequisitions">
          <SectionHeadline :text="mixWB('PREVIOUS_REQUISITIONS')" />

          <template v-if="requisitionFileList.length">
            <FileRow
              v-for="item in orderedFileList"
              :key="item.timestamp + item.name"
              :fileObject="item"
              :allowDelete="false" />
          </template>

          <template v-else>
            <p class="EmptyText">{{
              mixWB('NO_REQUISITIONS_TO_SHOW')
            }}</p>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/ResultItems/Header.vue'
import SectionHeadline from '@/components/FormElements/SectionHeadline.vue'
import Button from '@/components/Buttons/Button.vue'
import { mapGetters } from 'vuex'
import FileRow from '@/components/ResultItems/FileRow.vue'
import { orderBy } from 'lodash-es'

export default {
  name: 'OverviewRequisition',
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'currentScreening',
      'screeningSamples',
      'requisitionFileList',
      'isRequisitionFlowAvailable',
    ]),
    orderedFileList() {
      return orderBy(this.requisitionFileList, 'timestamp', 'desc')
    },
  },
  components: {
    Header,
    SectionHeadline,
    Button,
    FileRow,
  },
}
</script>

<style lang="stylus" scoped>
  .PageContentWrap
    page-wrap-outer-1()
    padding-top 0

  .InnerWrap
    page-wrap-inner-2()
    > * + *
      margin-top 50px

  .NewRequisition
    .ButtonWrap
      display inline-block

  .EmptyText
    font-style italic
    color $color_grey

</style>
